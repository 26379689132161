import crsCompanies from '../rest/crs/crsCompanies'

const AgentSearch = {
    searchTypes() {
        const searchInput = $('#lk-suggestion-input')
        return [
            {
                label: 'TIN',
                value: 'inn',
                params: (request) => {
                    return {
                        method: 'GET',
                        url: `/companies/find-by-inn`,
                        params: {inn: request}
                    }
                },
                validate: (val) => {
                    return Number.isInteger(Number(val))
                },
                set: () => {
                    searchInput.attr('placeholder', '500100732259')
                },
                processResponse: (response) => {
                    const data = response.data
                    data.label = data.inn
                    data.content = `<span class="lk-dropdown__title">${data.inn}, </span> ${data.name}, CRS id ${data.id}`
                    response.data = [data]
                    return response
                },
            },
            {
                label: 'Company name',
                value: 'name',
                params: (request) => {
                    return {
                        method: 'GET',
                        url: `/companies/find-by-company-name`,
                        params: {name: request}
                    }
                },
                processResponse: (response) => {
                    const data = []
                    response.data.active.concat(response.data.inactive).forEach(company => {
                        data.push({
                            id: company.id,
                            label: company.name,
                            content: `${company.inn} <span class="lk-dropdown__title">${company.name}</span>, CRS id ${company.id}`
                        })
                    })
                    response.data = data
                    return response
                }
            },
            {
                label: 'Owner',
                value: 'owner',
                params: (request) => {
                    return {
                        method: 'POST',
                        url: `/companies/find-by-name`,
                        data: {name: request}
                    }
                },
                set: () => {
                    searchInput.attr('placeholder', 'Ivanov Ivan Ivanovich')
                },
                processResponse: (response) => {
                    response.data.forEach(data => {
                        data.label = `${data.ownerLastNameNominative} ${data.ownerFirstNameNominative} ${data.ownerMiddleNameNominative}`
                        data.content = `<span class="lk-dropdown__title">${data.label}, </span> ${data.inn} ${data.name}, CRS id ${data.id}`
                    })
                    return response
                },
            },
            {
                label: 'CRS id',
                value: 'crsId',
                params: (request) => {
                    return {
                        method: 'GET',
                        url: `/companies/one`,
                        params: {id: request}
                    }
                },
                validate: (val) => {
                    return Number.isInteger(Number(val))
                },
                processResponse: (response) => {
                    const data = response.data
                    data.label = data.id
                    data.content = `${data.inn}, ${data.name}, <span class="lk-dropdown__title">CRS id ${data.id}</span>`
                    response.data = [data]
                    return response
                },
            }
        ]
    },
    selectItem(id) {
        $('[name="form_orders_filter"]').append(`<input type="hidden" value="${id}"  name="filter[agency_id]" id="lk-suggestion__dropdown-selected"/>`)
        crsCompanies.managersList(id)
            .then(response => {
                const managersListSelect = $('select[name="filter[owner]"]')
                managersListSelect.find('option:gt(0)').remove()
                response.data.data.forEach(manager => {
                    managersListSelect.append(`<option value="${manager.id}">${manager.lastName} ${manager.firstName} ${manager.patronymicName}</option>`)
                })
                managersListSelect.attr('disabled', false)
                managersListSelect.closest('.b-form__item').find('.b-form__label').removeClass('disabled')
                managersListSelect.selectric('refresh')
            }).catch(error => console.error(error))
    },
    processResponse(response) {
        if (response.result === 'OK') {
            const lkSuggestionDropdown = $('.lk-suggestion__dropdown')
            lkSuggestionDropdown.empty()
            response.data.forEach(data => $('.lk-suggestion__dropdown').append(this.getHtml(data)))
            lkSuggestionDropdown.show()
        }
    },
    getHtml(data) {
        return `<div class="user_item lk-dropdown__item" data-id="${data.id}" data-label="${data.label}">
                    ${data.content}
                </div>`;
    }
}

module.exports = AgentSearch

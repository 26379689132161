import * as vanillaTextMask from './_vendor/vanillaTextMask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import SlimSelect from '../js/_vendor/slimselect'
import {listCountries} from '../js/_vendor/phone-codes'
import cookie from '../js/cookie'

let inputMask = {
	run() {
		let dateMask = [/[0-3]/, /\d/, '.', /[0-1]/, /\d/, '.', /[1-2]/, /\d/, /\d/, /\d/]
		let timeMask = [/[0-9]/, /\d/, ':', /[0-9]/, /\d/, ':', /[0-9]/, /\d/]
		let docNumberMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
		let issuererMask = /[!@;:'"#№~\$%^&*\(\)_\-+=\?\\\|\/\[\]\{\}]/g
		let myInput = document.querySelectorAll('[data-mask]')
		let timeElement = document.querySelectorAll('[data-mask="time"]')
		let dateElement = document.querySelectorAll('[data-mask="date"]')
		let $phoneFields = $('[data-mask="phone"]')
		let self = this

		function validate(e) {
			let self = this
			let currPhone = self.querySelector('[data-mask="phone"]')
			let currPhoneValue = currPhone.value
			let currPhoneValidateValue = currPhoneValue.search('_')

			if (currPhoneValidateValue == -1 && currPhoneValue.length != 0) {
				currPhone.classList.remove('error')
				if (currMail.nextElementSibling != null) {
					currPhone.nextElementSibling.classList.remove('active')
				}
			} else {
				e.preventDefault()
				currPhone.classList.add('error')
				if (currMail.nextElementSibling != null) {
					currPhone.nextElementSibling.classList.add('active')
				}
			}
		}

		const maskOpts = {
			definitions: {
				'#': {
					validator: '[0-9]',
					cardinality: 1,
				},
			},
			onUnMask: function (maskedValue, unmaskedValue) {
				return maskedValue.replace(/[^\d]/g, '')
			},
			showMaskOnHover: false,
			autoUnmask: true,
			clearMaskOnLostFocus: true,
			removeMaskOnSubmit: true,
		}
		let n = 1
		let selectedMask = '+7(###)###-##-##'
		$phoneFields.each(function () {
			const input = $(this)
			const container = input.closest('.b-form__item')
			if (!input.hasClass('phone-with-country')) {
				input.addClass('phone-with-country')
				input.inputmask('remove')
				input.inputmask(maskOpts)
				const opts = []
				const lang = document.documentElement.lang
				for (let country of listCountries) {
					const cc = (country.cc + '').toLowerCase()
					const opt = {
						innerHTML: `<div class="country-code__selected-code iti__flag iti__${cc}"></div><div class="country-code__selected-name">${country[`name_${lang}`]}</div>`,
						value: country.mask,
						text: country[`name_${lang}`],
						selected: cc === 'ru',
					}
					opts.push(opt)
				}
				
				if(container
					.parents('.country-code__container').length < 1){
					container.wrap('<div class="country-code__container"></div>')
				}	
				
				if($("[id^='country-code-']").length < 1){
						input.closest('.country-code__container')
					.prepend('<select id="country-code-' + n + '" class="country-coder"></select>')
				}	

				input.attr('data-code-selector-id', `country-code-${n}`)
				try {
					new SlimSelect({
						select: `#country-code-${n}`,
						valuesUseText: false,
						searchPlaceholder: 'Поиск',
						data: opts,
						onChange: info => {
							maskOpts.mask = info.value
							selectedMask = info.value
							input.inputmask('option', {mask: info.value})
							input.val('')
						},
					})
					
				} catch (err) {
				}
				n++
			}
		})

		$(document).on('click', '.b-form--recovery button[name="save"]', function () {
			cookie.setCookie('countryCodeMask', selectedMask)
		})

		$(document).ready(() => {
			$phoneFields.each(function () {
				const input = $(this)
				if (input.val()) {
					const phone = input.val()
					const savedCountryMask = cookie.getCookie('countryCodeMask')
					if (savedCountryMask) {
						const codeSelector = document.getElementById(input.attr('data-code-selector-id'))
						if (!!codeSelector) {
							codeSelector.slim.set(savedCountryMask)
							input.inputmask('option', {mask: savedCountryMask.replace(/[0-9]/g, '#')})
							input.inputmask('setvalue', phone)
						}
					}
				} else {
					input.inputmask('option', {mask: '+7(###)###-##-##'})
				}
			})
			let sms = $('[name=CODE]')
			if (sms && sms.is(':visible')) {
				sms.focus()
			}
		})

		for (let i = 0; i < timeElement.length; i++) {
			timeElement[i].onfocus = function () {
				this.placeholder = '__:__:__'
			}

			vanillaTextMask.maskInput({
				inputElement: timeElement[i],
				mask: timeMask,
				showMask: false,
				guide: false,
			})
		}

		for (let i = 0; i < myInput.length; i++) {
			let currPlaceholder = myInput[i].placeholder

			myInput[i].onblur = function () {
				this.placeholder = currPlaceholder
			}
		}

		const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd.mm.yyyy', {minYear: 1870, maxYear: 9999})

		for (let i = 0; i < dateElement.length; i++) {
			vanillaTextMask.maskInput({
				inputElement: dateElement[i],
				mask: dateMask,
				showMask: false,
				pipe: autoCorrectedDatePipe,
				keepCharPositions: true,
			})
		}

		$('.lk-friends .b-form.disabled, .lk-edit .b-form.disabled').each(function (index, item) {
			setTimeout(() => {
				self.updFormDatepickers({$form: $(item)})
			}, 10)
		})

		MyDocMask()

		$('[data-action="edit"]').on('click', function (e) {
			setTimeout(() => {
				MyDocMask()

				self.updFormDatepickers({$form: $(e.currentTarget).closest('.lk-items__item')})

				if (
					$(e.currentTarget).closest('.lk-items__item').find('.js-second-name-checkbox .b-checkbox__input')
						.length
				) {
					$(e.currentTarget)
						.closest('.lk-items__item')
						.find('.js-second-name-checkbox .b-checkbox__input')
						.each((index, item) => {
							initSecondNameRequired($(item))
						})
				}
			}, 0)
		})

		function initSecondNameRequired($input) {
			const $wrapper = $input.closest('.b-form__item-second-name')
			const $inpSecondName = $wrapper.find('.b-form__input-second-name')
			if (!$inpSecondName.val().length) {
				$inpSecondName.attr('data-required', '')
				$input.prop('checked', true)
			} else {
				$inpSecondName.attr('data-required', 'Y')
				$input.prop('checked', false)
			}
		}

		$('[name="UF_DOCUMENT_ISSUERER"], [name="documentIssuerer"]').on('keyup change', function () {
			const value = $(this).val()
			if (issuererMask.test(value)) {
				const replaced = value.replace(issuererMask, '')
				$(this).val(replaced)
			}
		})

		function MyDocMask() {
			$('.lk-friends .b-form:not(.disabled), .lk-edit .b-form:not(.disabled)').each(function () {
				let maskedInputController
				let selectDoc = $(this).find('[name="UF_DOCUMENT"], [name="documentType"]').first()
				const docDateElement = $(this).find('[name="UF_DOCUMENT_DATE"], [name="documentIssueDate"]').first()
				let docSeriesElement = $(this)
					.find('[name="UF_DOCUMENT_SERIES"], [name="documentSeparateSeries"]')
					.first()
				let docNumberElement = $(this)
					.find('[name="UF_DOCUMENT_NUMBER"], [name="documentSeparateNumber"]')
					.first()

				if (selectDoc.length && docNumberElement.length) {
					docMask()
				}

				selectDoc.on('change', function (e) {
					docMask()
				})

				docDateElement.on('change', function () {
					validateDocDate($(this))
				})

				function docMask() {
					const bDay = selectDoc.closest('form').find('input[type="hidden"][name="BIRTHDAY"]')

					if (bDay.length) {
						var splitedBDay = bDay.val().split('.')
						var day = splitedBDay[0]
						var month = splitedBDay[1]
						var year = parseInt(splitedBDay[2])
						var minAge = 13
					}

					const dateStr = $('[data-datepicker] > input').val()
					const splitedCurDate = dateStr.split('.')
					const curDay = splitedCurDate[0]
					const curMonth = splitedCurDate[1]
					const curYear = parseInt(splitedCurDate[2])
					const curDate = new Date(curYear + '-' + curMonth + '-' + curDay)

					const datepicker = $('[data-datepicker] > input').data('datepicker')

					if (docSeriesElement.length) {
						docSeriesElement.closest('.b-form__item').css('display', 'block')
						docSeriesElement.attr('data-required', 'Y')
					}

					if (selectDoc.find('option:selected').val() === '1') {
						if (maskedInputController != null) maskedInputController.destroy()
						if (docSeriesElement.length) docSeriesElement.inputmask('9{2} 9{2}')
						setTimeout(() => {
							maskedInputController = vanillaTextMask.maskInput({
								inputElement: docNumberElement[0],
								mask: docNumberMask,
								showMask: false,
							})
						}, 0)
						if (docSeriesElement.length) docSeriesElement[0].placeholder = '__ __'
						docNumberElement[0].placeholder = '______'
						if (docSeriesElement.length)
							docSeriesElement[0].value = docSeriesElement[0].value
								.split(/[IVXLCУХЛС_\-А-Я\s]+/g)
								.join('')
						docNumberElement[0].value = docNumberElement[0].value.replace(/_\s+/g, '')

						if (docDateElement.length && bDay.length) {
							if (!isNaN(curDate.getTime())) datepicker.selectDate(curDate)
							datepicker.update({
								minDate: new Date(year + minAge + '-' + month + '-' + day),
							})
						}

						docNumberElement.off('keyup change', self.maskDocNumber)
					} else if (selectDoc.find('option:selected').val() === '5') {
						if (docSeriesElement.length) docSeriesElement.closest('.b-form__item').css('display', 'block')
						if (docSeriesElement.length) docSeriesElement.attr('data-required', 'Y')
						if (maskedInputController != null) maskedInputController.destroy()
						/*TODO формат маски для серии [IVXLC1УХЛС]+-[А-Я]{2}*/
						if (docSeriesElement.length)
							docSeriesElement.inputmask({
								mask: '*{1,5}-X{2}',
								greedy: false,
								definitions: {
									'*': {
										validator: '[IVXLC1УХЛС]',
										casing: 'upper',
									},
									X: {
										validator: '[А-Я]',
										casing: 'upper',
									},
								},
							})
						setTimeout(() => {
							maskedInputController = vanillaTextMask.maskInput({
								inputElement: docNumberElement[0],
								mask: docNumberMask,
								showMask: false,
							})
						}, 0)
						if (docSeriesElement.length) docSeriesElement[0].placeholder = '_-__'
						docNumberElement[0].placeholder = '______'
						if (docSeriesElement.length)
							docSeriesElement[0].value = docSeriesElement[0].value.split(/[_\-\s]/).join('')
						docNumberElement[0].value = docNumberElement[0].value.replace(/_+/g, '')

						if (docDateElement.length && bDay.length) {
							if (!isNaN(curDate.getTime())) datepicker.selectDate(curDate)
							datepicker.update({
								minDate: new Date(year + '-' + month + '-' + day),
							})
						}

						docNumberElement.off('keyup change', self.maskDocNumber)
					} else {
						if (maskedInputController != null) maskedInputController.destroy()

						if (docSeriesElement.length) docSeriesElement.closest('.b-form__item').css('display', 'none')
						if (docSeriesElement.length) docSeriesElement.attr('data-required', '')
						if (docSeriesElement.length) docSeriesElement[0].value = ''
						docNumberElement[0].value = docNumberElement[0].value.split(/[_\-\s][A-я]/).join('')
						if (docSeriesElement.length) docSeriesElement[0].placeholder = ''
						docNumberElement[0].placeholder = ''

						if (docDateElement.length && bDay.length) {
							if (!isNaN(curDate.getTime())) datepicker.selectDate(curDate)
							datepicker.update({
								minDate: new Date(year + '-' + month + '-' + day),
							})
						}
					}

					docDateElement.trigger('change')

					if (
						typeof selectDoc.find('option')[1] !== 'undefined' &&
						selectDoc.find('option')[1].selected === true
					) {
						docNumberElement.on('keyup change', self.maskDocNumber)
					} else {
						docNumberElement.off('keyup change', self.maskDocNumber)
					}
				}

				function validateDocDate($input) {
					const formatInpDate = self.formatDate($input)
					const inpDate = new Date(formatInpDate.date)
					const $bDay = $('input[name="BIRTHDAY"]')
					const formatBDay = self.formatDate($bDay)
					const bDayDate = new Date(formatBDay.date)
					const age = 13
					const ageDate = new Date(
						formatBDay.month + '.' + formatBDay.day + '.' + (parseInt(formatBDay.year) + age),
					)
					const curDate = new Date()
					const $selectDoc = $('select[name="UF_DOCUMENT"], select[name="documentType"]').first()

					if (isNaN(inpDate.getTime())) {
						self.showError({
							$input: $input,
							text: 'Введите корректную дату',
						})
						self.clearInp({$input: $input})
						return false
					}

					if ($bDay.length && !isNaN(bDayDate.getTime()) && inpDate.getTime() < bDayDate.getTime()) {
						self.showError({
							$input: $input,
							text: 'Дата меньше даты рождения',
						})
						self.clearInp({$input: $input})
						return false
					}

					if (inpDate.getTime() > curDate.getTime()) {
						self.showError({
							$input: $input,
							text: 'Дата больше текущей даты',
						})
						self.clearInp({$input: $input})
						return false
					}

					if ($selectDoc.length && $selectDoc.val() === '13' && inpDate.getTime() < ageDate.getTime()) {
						self.showError({
							$input: $input,
							text: 'Документ нельзя получить не достигнув 13 летнего возраста',
						})
						self.clearInp({$input: $input})
						return false
					}

					self.hideError({$input: $input})
				}
			})
		}
	},

	updFormDatepickers(props) {
		if (!props.$form.length) return false

		const $datepickers = props.$form.find('[data-form-field-date]')

		$datepickers.each((index, item) => {
			const formated = this.formatDate($(item), $(item).attr('value'))
			const airDatepicker = $(item).data('datepicker')

			if (typeof airDatepicker !== 'undefined') {
				airDatepicker.selectDate(new Date(formated.date))
			}
		})
	},

	clearInp(props) {
		props.$input.val('')
	},

	showError(props) {
		const $box = props.$input.closest('.b-form__item')
		const $error = $box.find('.b-form__text[data-error-item]')
		$error.html(props.text)
		$box.addClass('error')
	},

	hideError(props) {
		const $box = props.$input.closest('.b-form__item')
		const $error = $box.find('.b-form__text[data-error-item]')
		$error.html('')
		$box.removeClass('error')
	},

	formatDate($input, value) {
		let result = {}
		if (!$input.length) return result

		const splitedBDay = typeof value === 'string' && value.length ? value.split('.') : $input.val().split('.')
		result.day = splitedBDay[0]
		result.month = splitedBDay[1]
		result.year = splitedBDay[2]
		result.date = splitedBDay[1] + '.' + splitedBDay[0] + '.' + splitedBDay[2]

		return result
	},

	maskDocNumber(evt) {
		const $input = $(evt.currentTarget)
		const value = $input.val()
		const re = /[!@;:'"#№~\$%^&*\(\)_\-+=\?\\\|\/\[\]\{\}]/g
		const replaced = value.replace(re, '')

		if (re.test(value)) {
			$input.val(replaced)
		}
	},
}

$('#search_types').on('change', function (e) {
	if (this.value !== 'phone') {
		$('.history-filter__list .country-coder').unwrap();
		$('.ss-main.country-coder').remove();
		$('.country-coder').remove();
	}
});

module.exports = inputMask

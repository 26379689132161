// eslint-disable-next-line no-unused-vars
import selectric from 'selectric'

// eslint-disable-next-line no-underscore-dangle
let _this = this
const select = {
	selectItems: [],
	run() {
		_this = this
		this.default()
		this.multiple()
		this.link()
		this.updCityEndSelect()
		document.addEventListener(`selectTrigger`, {
			handleEvent: () => {
				const selectItemsLength = select.selectItems.length
				let selectItemsIndex = 0
				if (selectItemsLength) {
					for (selectItemsIndex; selectItemsIndex < selectItemsLength; selectItemsIndex++) {
						const optionsLength = select.selectItems[selectItemsIndex].options.length
						let optionsIndex = 0
						for (optionsIndex; optionsIndex < optionsLength; optionsIndex++) {
							const state = $(select.selectItems[selectItemsIndex].options[optionsIndex]).prop('disabled')
							$(select.selectItems[selectItemsIndex].select.find('option')[optionsIndex]).prop(
								'disabled',
								state,
							)
						}
						select.selectItems[selectItemsIndex].select.data('selectric').refresh()
					}
				}
			},
		})
	},
	updCityEndSelect() {
		function updDropdownPos(props) {
			const $box = props.$item.parent()

			if (!props.$item.length || !$box.length) return false

			const $dropdown = props.$item.find('.select-items')
			const offsetLeft = -1 * (props.$item.offset().left - $box.offset().left)
			$dropdown.css('left', offsetLeft)
		}

		updDropdownPos({
			$item: $('.c-filter__item-city-end'),
		})
	},
	updShipSelect() {
		function updDropdownPos(props) {
			const $dropdown = props.$item.find('.select-items')
			const $box = props.$item.parent()
			const offsetLeft = -1 * (props.$item.offset().left - $box.offset().left)
			$dropdown.css('left', offsetLeft)
		}

		updDropdownPos({
			$item: $('.c-filter__item--motor_ship'),
		})
	},
	multiple() {
		$('[multiple]:not([data-select-multiple-simple])').each(function () {
			const $this = $(this)
			const $item = $this.parents('.c-filter__item')
			const $checkboxes = $item.find('[type="checkbox"]')
			let $select /* vanila select */
			let $positionWrap
			let $wrap
			let $list
			let $scroll
			let $listWrap
			let $inputLabel /* заголовок всего поля */
			let $selectLabel /* элементы селекта */
			let $itemStateIndex
			let $itemFormIndex

			$this.selectric({
				labelBuilder: currItem =>
					`<span data-index="${currItem.index}">${currItem.text} <span class="plus plus--cross"></span></span>`,
				multiple: {
					separator: '',
					keepMenuOpen: true,
					maxLabelEntries: false,
				},
				minScrollbarLength: 67,
				onInit() {
					$select = $(this)
					$itemFormIndex = $select[0].getAttribute('data-filter-item-code')
					$positionWrap = $select.parents('[data-select-wrap]')
					$wrap = $select.parents('.select-wrapper')
					$list = $wrap.find('.select-items')
					$listWrap = $wrap.find('.select-scroll')
					$inputLabel = $wrap.prev('[data-f-label]')
					$selectLabel = $inputLabel.find('span')
					if (typeof smartFilter !== 'undefined' && smartFilter) {
						$itemStateIndex = smartFilter.customStateFilter.addItems($select.data('selectric'))
					}

					if ($checkboxes.length)
						select.selectItems.push({
							select: $select,
							options: $checkboxes,
						})

					full()
					itemClickHandler()

					if ($listWrap.length > 0)
						$scroll = new PerfectScrollbar($listWrap[0], {
							wheelSpeed: 0.5,
							minScrollbarLength: 67,
							suppressScrollX: true,
						})
				},
				onOpen() {
					if (_this.smartFilterIsDefined() && smartFilter) {
						smartFilter.customStateFilter.refreshItemOpenedState(true, $itemStateIndex)
					}
					scrollHandler()
					if ($('[data-main-filter]')) {
						const $selects = $('[data-main-filter]').find('.select-items')
						$selects.each(function () {
							let listHeight = $(this).height()
							listHeight += 8
							$(this).css('top', -listHeight)
						})
					}
					$scroll.update()
				},
				onSelect(select, selectric, i) {
					if ($checkboxes.length) toggleCheckbox(i, true)
				},
				onRefresh() {
					itemClickHandler(false)
					full()
				},
				onChange: itemClickHandler,
				onClose() {
					if (_this.smartFilterIsDefined() && smartFilter) {
						smartFilter.customStateFilter.refreshItemOpenedState(false, $itemStateIndex)
					}
				},
			})
			const selectricItem = $select.data('selectric')

			/* обработка клика по выбранным вариантам в лейбле */
			function itemClickHandler(needRefresh = true) {
				const itemsLength = $select.find('option:selected').length
				const $labelInnerItems = $item.find('.label > span')

				if (itemsLength > 0) {
					$selectLabel.text(itemsLength)
					$wrap.addClass('select-wrapper--content')
					$inputLabel.addClass('active active--origin')
				} else {
					$wrap.removeClass('select-wrapper--content')
					$selectLabel.text('')
					$inputLabel.removeClass('active active--origin')
				}

				// $labelInnerItems.off('click.remove');
				$labelInnerItems.on('click.remove', function (e) {
					e.stopPropagation()
					const $currentIndex = $(this).data('index')

					// TODO: needRefresh последний элемент мультиселекта в ЛКА заказы всегда имеет false, в остальных случаях - html элемент
					if ($(this).closest('.history-filter__item').length) needRefresh = true

					if ($checkboxes.length) toggleCheckbox($currentIndex)

					$($select.find('option')[$currentIndex]).prop('selected', false)

					if (needRefresh) $this.selectric('refresh')

					$scroll.update()
				})
			}

			function full() {
				$list.addClass('select-items--multiple')

				let forceFull = false
				if ($select.is('[data-force-full="true"]')) {
					forceFull = true
				}
				if ($select.children().length > 20 || forceFull) {
					$wrap.addClass('select-wrapper--full')
					$item.addClass('c-filter__item--full')
					updDropdownPos({
						$item,
					})
				}
			}

			function updDropdownPos(props) {
				const $dropdown = props.$item.find('.select-items--multiple')
				const $box = props.$item.parent()
				const offsetLeft = -1 * (props.$item.offset().left - $box.offset().left)
				$dropdown.css('left', offsetLeft)
			}

			function scrollHandler() {
				/* отслеживается нажатие в пределах блока скролла, если мышка уходит, то обработчик не навешивается */
				$('.ps__thumb-y').on('click', function (e) {
					e.preventDefault()
					e.stopPropagation()
				})
			}

			function toggleCheckbox(i, isSelected) {
				const currentCheckbox = $checkboxes[i]
				const state = $(currentCheckbox).prop('checked')
				$(currentCheckbox).prop('checked', !state)
				if (_this.smartFilterIsDefined() && smartFilter) {
					smartFilter.click(currentCheckbox, '')
				}
			}

			function toggleSelect() {
				const $checkboxesLength = $checkboxes.length
				let $checkboxesIndex = 0
				for ($checkboxesIndex; $checkboxesIndex < $checkboxesLength; $checkboxesIndex++) {
					const state = $($checkboxes[$checkboxesIndex]).prop('disabled')
					$($select.find('option')[$checkboxesIndex]).prop('disabled', state)
					selectricItem.refresh()
				}
			}
		})
	},
	smartFilterIsDefined() {
		return typeof smartFilter !== 'undefined'
	},

	link() {
		$('[data-select-link]').each(function () {
			const $this = $(this)
			let $wrap
			let $item
			let $scroll
			let $links

			$this.selectric({
				labelBuilder: currItem => `<span class="${$(currItem.element).data('order')}"></span>${currItem.text}`,
				optionsItemBuilder: currItem =>
					`<a href="${currItem.value}"><span class="${$(currItem.element).data('order')}"></span>${
						currItem.text
					}</a>`,
				customClass: {
					prefix: 'select',
					camelCase: false,
				},
				arrowButtonMarkup: '',
				disableOnMobile: false,
				nativeOnMobile: false,
				minScrollbarLength: 67,
				onInit() {
					const $select = $(this) /* сам скрываемый тег select */
					$wrap = $select.parents('.select-wrapper').find('ul')
					$item = $select.parents('.select-wrapper').find('.select')
					$links = $wrap.find('a')
					$select.addClass('hidden')
					$wrap.addClass('select-ul')

					$links.on('click', e => e.stopPropagation())

					const $listWrap = $select.parents('.select-wrapper').find('.select-scroll')
					if ($listWrap.length > 0)
						$scroll = new PerfectScrollbar($listWrap[0], {
							wheelSpeed: 0.5,
							minScrollbarLength: 67,
							suppressScrollX: true,
						})
				},
			})
		})
	},

	default() {
		$('select:not(.hidden):not([data-select-multiple]):not([data-select-link])').each(function () {
			const $this = $(this)
			const $radioboxes = $this.parents('.c-filter__item').find('[type="radio"]')
			let $select /* сам скрываемый тег select */
			let $wrap
			let $item
			let $scroll
			let $itemFormIndex

			$this.selectric({
				customClass: {
					prefix: 'select',
					camelCase: false,
				},
				arrowButtonMarkup: '',
				disableOnMobile: false,
				nativeOnMobile: false,
				multiple:typeof $this.attr("data-select-multiple-simple") !== 'undefined' ? {
					separator: ',',
					keepMenuOpen: false,
					maxLabelEntries: true,
				} : false,
				onInit() {
					$select = $(this) /* сам скрываемый тег select */
					$itemFormIndex = $select[0].getAttribute('data-filter-item-code')
					$wrap = $select.parents('.select-wrapper').find('ul')
					$item = $select.parents('.select-wrapper').find('.select')
					$select.addClass('hidden')
					$wrap.addClass('select-ul')
					const $listWrap = $select.parents('.select-wrapper').find('.select-scroll')
					if ($listWrap.length > 0)
						$scroll = new PerfectScrollbar($listWrap[0], {
							wheelSpeed: 0.5,
							minScrollbarLength: 67,
							suppressScrollX: true,
						})

					if ($radioboxes.length)
						select.selectItems.push({
							select: $select,
							options: $radioboxes,
						})

					if ($select.is('[data-force-full="true"]')) {
						full()
					}
					if ($select.val()) {
						$select[0].prevValue = $select.val()
					}
				},
				onOpen() {
					if ($select.is('[data-force-full="true"]')) {
						$select.parents('.select-wrapper').addClass('select-wrapper--full')
					}
					if($scroll) {
						$scroll.update()
					}
					scrollHandler()

					if ($('[data-main-filter]')) {
						const $selects = $('[data-main-filter]').find('.select-items')
						$selects.each(function () {
							let listHeight = $(this).height()
							listHeight += 8
							$(this).css('top', -listHeight)
						})
					}
				},
				onSelect(select, selectric, i) {
					if (select.getAttribute('data-allow-deselect')) {
						if (select.prevValue) {
							if (select.prevValue === select.value) {
								select.prevValue = null;
								$(select).val('').change().selectric('refresh');
							} else {
								select.prevValue = select.value
							}
						} else {
							select.prevValue = select.value
						}
					}
					if ($radioboxes.length) toggleRadiobox(i)
				},
				onClose() {},
				onChange() {
					$(this).trigger('change')
				},
			})

			function scrollHandler() {
				/* отслеживается нажатие в пределах блока скролла, если мышка уходит, то обработчик не навешивается */
				$('.ps__thumb-y').on('click', function (e) {
					e.preventDefault()
					e.stopPropagation()
				})
			}

			function full() {
				if ($select.attr('data-filter-item-code') == 'CITY_END') {
					$wrap = $select.parents('.select-wrapper').addClass('select-wrapper--full')
				} else {
					$item.addClass('c-filter__item--full')
				}
				$wrap.addClass('select-wrapper--full')
			}

			function toggleRadiobox(i) {
				const currentRadiobox = $radioboxes[i]
				const state = $(currentRadiobox).prop('checked')

				$(currentRadiobox).prop('checked', !state)

				if (_this.smartFilterIsDefined() && smartFilter) smartFilter.click(currentRadiobox)
			}

			function toggleSelect() {
				const $radioboxesLength = $radioboxes.length
				let $radioboxesIndex = 0
				for ($radioboxesIndex; $radioboxesIndex < $radioboxesLength; $radioboxesIndex++) {
					const state = $($radioboxes[$radioboxesIndex]).prop('disabled')
					$($select.find('option')[$radioboxesIndex]).prop('disabled', state)
					$select.data('selectric').refresh()
				}
			}
		})
	},
}

module.exports = select

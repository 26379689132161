export class YandexCaptcha {
	widgetId
	container
	static widgets = {}

	constructor(container) {
		this.container = container
		if (window.smartCaptcha) {
			this.widgetId = window.smartCaptcha.render(container, {
				sitekey: window.CONSTANTS.CAPTCHA_SITE_KEY,
				hl: document.documentElement.lang
			});
			window.smartCaptcha.subscribe(this.widgetId)
		}
	}

	static subscribe(container) {
		const instance = new this(container)
		$(container).closest('form').on('submit', function () {
			if (!instance.isValid()) {
				if (!$(container).hasClass('error')) {
					$(container).addClass('error');
				}
				if ($(container).find('.b-form__text').length === 0) {
					$(container).append('<span class="b-form__text" data-error-item>Поле обязательно для заполнения</span>');
				}
				return false
			} else {
				$(container).find('.b-form__text').remove();
				$(container).removeClass('error');
			}
		})
		YandexCaptcha.widgets[container.id] = instance.widgetId
	}

	static getWidgetId(id) {
		return YandexCaptcha.widgets[id]
	}

	isValid() {
		return window?.smartCaptcha?.getResponse(this.widgetId) !== ''
	}
}
